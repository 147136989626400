<template>
  <div class="login">
    <div class="header">
      <img src="@/assets/handlers/top-bg.png" alt="">
    </div>
    <div class="login-form">
      <a-spin tip="Loading..." :spinning="loading">
        <a-form>
          <a-form-item has-feedback v-bind="validateInfos.Uname">
            <a-input class="a-input" v-model:value="modelRef.Uname">
              <template #prefix>
                <UserOutlined />
              </template>
            </a-input>
          </a-form-item>

          <a-form-item has-feedback v-bind="validateInfos.Pwd">
            <a-input
              class="a-input"
              v-model:value="modelRef.Pwd"
              type="password"
            >
              <template #prefix>
                <LockOutlined />
              </template>
            </a-input>
          </a-form-item>

          <div class="btn" @click="onSubmit">
            <img src="@/assets/mobile/login-btn-bg.png" alt="">
          </div>
        </a-form>
      </a-spin>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import storage from 'store'

const useForm = Form.useForm
export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined
  },
  mixins: [form],
  setup () {
    const loading = ref(false)

    const modelRef = reactive({
      Uname: '',
      Pwd: ''
    })

    const rulesRef = reactive({
      Uname: [
        {
          required: true,
          message: '请输入用户名'
        }
      ],
      Pwd: [
        {
          required: true,
          message: '请输入密码'
        }
      ]
    })

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    return {
      loading,
      modelRef,
      validateInfos,
      resetFields,
      validate
    }
  },

  created () {
    const userlogin = storage.get('USERLOGIN')
    if (userlogin) {
      this.modelRef.Uname = userlogin.Uname
      this.modelRef.Pwd = userlogin.Pwd
    }
  },

  methods: {
    onSubmit () {
      this.loading = true
      this.validate()
        .then(() => {
          this.$store
            .dispatch('SLogin', toRaw(this.modelRef))
            .then(() => {
              this.loading = false
              storage.set('USERLOGIN', toRaw(this.modelRef))
              this.$message.success('登录成功')
              this.$router.push({ name: 'handlers' })
            })
            .catch((e) => {
              this.loading = false
            })
        })
        .catch(() => {
          this.loading = false
          this.$message.error('请正确输入账户信息')
        })
    }
  }
})
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100vh;
  background: #fff;
  .login-form{
    padding: .80rem 1.00rem;
    .a-input{
      border: none;
      height: .94rem;
      border-bottom: 1px solid #c9c9c9;
    }

    .btn{
      width: 5.40rem;
      box-shadow: 0 0 .24rem rgba(0, 128, 182, 0.4);
      border-radius: .10rem;
      img{
        display: block;
        width: 100%;
      }
    }
  }
  .header{
    img{
      display: block;
      width: 100%;
    }
  }
}
</style>
